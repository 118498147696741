import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { imagesFrontPage } from '../../config/path';

// Global Styles to Reset Margin, Padding and Box Sizing
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden; // Prevent horizontal scrolling
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background: url(${imagesFrontPage.background}) center center / cover no-repeat fixed;
  width: 100vw; // Use vw unit to ensure it takes up exactly the width of the viewport
  overflow: hidden; // Ensures no spillover content causes scrolling
`;

const Navbar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: 50px; // Adjust size as needed
  width: auto;
  margin-right: 10px; // Adds some space between the image and text
`;

const LogoText = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  color: #F5F5DC; 
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const MainContent = styled.section`
  text-align: center;
  color: #fff;
  max-width: 500px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
`;

const Heading = styled.h2`
  font-size: 3rem;
  margin-bottom: 20px;
  color: #F5F5DC; 
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #000;
  background-color: #357ABD; // A vibrant blue
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #F5F5DC; // Consider changing this to a darker shade if too bright
    transform: translateY(-2px);
  }
`;

const ActionButtonPrivacy = styled.a`
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #000;
  background-color: #357ABD; // A vibrant blue
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; // Remove underline typical of anchor tags
  display: inline-block; // To allow padding and width to work like a button
  transition: all 0.3s ease;

  &:hover {
    background-color: #F5F5DC;
    transform: translateY(-2px);
  }
`;

const ActionButtonExploreMap = styled.button`
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #000;
  background-color: #357ABD; // A vibrant blue
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #F5F5DC; // Consider changing this to a darker shade if too bright
    transform: translateY(-2px);
  }
`;

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.8rem;
`;

const WelcomeComponentDesktop = () => {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const password = prompt('Please enter the access code:', '');
    if (password === 'medseg') { // Remplacez 'your_access_code' par votre code de sécurité
      setAuthorized(true);
    } else {
      console.log("You are not a dev!")
    }
  }, [navigate]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  if (!authorized) {
    return null; // Ou un composant de chargement pendant que le mot de passe est vérifié
  }

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar>
          <Logo onClick={() => handleNavigate('/')}>
            <LogoImage src={imagesFrontPage.logo} alt="Dining with Kids Logo" />
            <LogoText>Dining with Kids</LogoText>
          </Logo>
          <NavLinks>
            <ActionButton onClick={() => handleNavigate('/contact')}>Contact Us</ActionButton>
            <ActionButtonPrivacy href="https://www.termsfeed.com/live/effda6a1-c463-4bf0-af0b-dd100a3cb6c1" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </ActionButtonPrivacy>
          </NavLinks>
        </Navbar>
        <MainContent>
          <Heading>Welcome to Dining with Kids!</Heading>
          <Description>
            Discover and share your favorite family-friendly restaurants with play areas. Join our community of parents who make dining out fun and easy!
          </Description>
          <ActionButtonExploreMap onClick={() => handleNavigate('/map')}>Explore Map</ActionButtonExploreMap>
        </MainContent>
        <Footer>
          © {new Date().getFullYear()} Dining with Kids. All rights reserved.
        </Footer>
      </Container>
    </>
  );
};

export default WelcomeComponentDesktop;
